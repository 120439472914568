import * as React from "react"
import {
    useEffect,
    useState
} from "react"
import "../assets/styles/index.scss"
import {Helmet} from "react-helmet"
import axios from "axios"

const IndexPage = ({history, location}) => {
    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [selectedMockup, setSelectedMockup] = useState(null)



    useEffect(() => {
        const query = new URLSearchParams(location.search)

        const b = query.get('b')
        const p = query.get('p')

        let url = `https://public.dreamship.com/p/${b}/${p}/`

        if (process.env.NODE_ENV === 'development') {
            url = `http://localhost:8000/public/p/${b}/${p}/`
        }

        const data = {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'X-header': 'anything',
            }
        }
        axios.get(url, data).then((resp) => {
            console.log(data)
            const products = resp.data.products
            setProducts(products)
            if (products.length > 0) {
                selectProduct(products[0])
            }
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
        })
    }, [])

    const selectProduct = (product) => {
        setSelectedProduct(product)
        if (product.mockups.length > 0) {
            selectMockup(product.mockups[0])
        } else {
            selectMockup(null)
        }
    }

    const selectMockup = (mockup) => {
        setSelectedMockup(mockup)
    }

    return (
        <main className="bg-light">
            <Helmet
                bodyAttributes={{
                    class: "overflow-hidden"
                }}
            >
                <meta charSet="utf-8"/>
                <title>Preview</title>
                <link rel="canonical" href="https://fulfilled.app"/>
            </Helmet>

            <div className="container-xxl g-0 bg-white border">
                <div className="row g-0">
                    {products.length > 0 &&
                    <>
                        <div className="col col-3 border vh-100 overflow-scroll">
                            <div className="list-group list-group-flush border-bottom">
                                {products.map((product) => {
                                    let classes = "list-group-item list-group-item-action text-truncate"
                                    if (product.id === selectedProduct?.id) {
                                        classes += " active"
                                    }
                                    return (
                                        <button
                                            key={product.id}
                                            className={classes}
                                            onClick={(e) => {
                                                selectProduct(product)
                                            }}
                                        >
                                            {product.name}
                                        </button>
                                    )
                                })}
                            </div>
                        </div>
                    </>
                    }
                    {selectedProduct?.mockups.length > 0 ?
                        <>
                            <div className="col vh-100 border-top border-bottom overflow-scroll p-4">
                                <h1 className="h2">
                                    {selectedProduct?.name}
                                </h1>
                                <p className="text-secondary">
                                    {selectedMockup?.name}
                                </p>
                                <img
                                    src={selectedMockup?.file}
                                    className="Image img-fluid"
                                    alt={selectedMockup?.name}
                                />
                            </div>

                            <div className="col col-2 border vh-100 overflow-scroll">
                                <div className="list-group list-group-flush border-bottom">
                                    {selectedProduct?.mockups.map((mockup) => {
                                        let classes = "list-group-item list-group-item-action"
                                        if (mockup.id === selectedMockup?.id) {
                                            classes += " active"
                                        }
                                        return (
                                            <button
                                                key={mockup.id}
                                                className={classes}
                                                onClick={(e) => {
                                                    selectMockup(mockup)
                                                }}
                                            >
                                                <figure className="figure my-2">
                                                    <img
                                                        src={mockup.thumbnail}
                                                        className="figure-img my-0 my-md-2 img-fluid"
                                                        alt={mockup.name}
                                                    />
                                                    <figcaption className="figure-caption d-none d-md-inline">
                                                        {mockup.name}
                                                    </figcaption>
                                                </figure>
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="col vh-100 d-flex align-items-center justify-content-center border-start-0">
                                <h1 className="text-secondary">
                                    {!loading && 'Missing Preview'}
                                </h1>
                            </div>
                        </>
                    }
                </div>
            </div>
        </main>
    )
}

export default IndexPage
